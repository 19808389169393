import {memo, useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {AvatarCell, Details, PackagesPrice, RecordDetails} from 'Admin/AdminDashboard/components/shared/index';
import {
  actions as adminOnlineReportActions,
  selectors as adminOnlineReportSelectors,
} from 'Admin/AdminDashboard/store/adminOnlineReport/index';
import {AdminOrdersModule} from 'Admin/AdminDashboard/store/adminOrders/adminOrdersModule';
import {
  actions as adminOrdersActions,
  actions as orderActions,
  selectors as adminOrdersSelectors,
  selectors as orderSelectors,
} from 'Admin/AdminDashboard/store/adminOrders/index';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import {FRONTEND_DATE3} from 'Common/constants/Date';
import {IconName} from 'Icon/components/Icon';

import ChooseAnotherSampleForm from 'Admin/AdminDashboard/components/Orders/ChooseAnotherSampleForm/ChooseAnotherSampleForm';
import useOnUpdateOrder from 'Admin/AdminDashboard/components/Orders/OrderHorses/useOnUpdateOrder';
import {IOrderHorseDetailsAdmin} from 'Admin/AdminDashboard/models/Order/IOrderHorseDetailsAdmin';
import {
  actions as onlineReportActions,
  selectors as onlineReportSelectors,
} from 'Admin/AdminDashboard/store/adminOnlineReport/index';
import {TestReportModule} from 'Admin/AdminDashboard/store/common/testReport';
import ChangeSampleForm from 'Admin/shared/components/ChangeSampleForm/ChangeSampleForm';
import HorseReportList from 'Admin/shared/components/HorseReportList/HorseReportList';
import RequiredTestsList from 'Admin/shared/components/RequiredTestsList/RequiredTestsList';
import {useReleaseOrderHorseAction} from 'Admin/shared/helpers/hooks/useOrderActions/useReleaseOrderHorseAction';
import {useRequestNewSampleAction} from 'Admin/shared/helpers/hooks/useOrderActions/useRequestNewSampleAction';
import {useSetSampleStatusAction} from 'Admin/shared/helpers/hooks/useOrderActions/useSetSampleStatusAction';
import {useUpdatePaymentModal} from 'Admin/shared/helpers/hooks/useOrderActions/useUpdatePaymentModal';
import {useUploadReportModal} from 'Admin/shared/helpers/hooks/useOrderActions/useUploadReportModal';
import ColorPalette from 'Common/constants/ColorPalette';
import {EventName} from 'Common/constants/EventName';
import {OrderStatus} from 'Common/constants/OrderStatus';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {useConfirmModal} from 'Common/helpers/hooks/useConfirmModal';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';
import withDate from 'Common/helpers/withDate';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IOrderStatused} from 'Common/models/IOrderStatused';
import {IAppState} from 'Common/store/IAppState';
import {PackageType} from '../../shared/PackagesPrice/PackagesPrice';
import SampleId from '../../shared/SampleId/SampleId';
import StatusDetails from './parts/StatusDetails';
import {IOrderHorseStatuses} from 'Admin/AdminDashboard/models/Order/IOrderHorseStatuses';
import {IOrderActivationCode} from 'ActivationCode/models/IOrderActivationCode';
import {acStatusToBadgeColor} from 'Common/helpers/activationStatusToBadgeColor';

const Column = RecordDetails.Column;
const iconProps = {color: ColorPalette.red7};

const menuItems = {
  updatePayment: {label: 'Update payment', value: 'updatePayment', icon: {name: IconName.Money, ...iconProps}},
  sampleReceived: {
    label: 'Mark sample as received',
    value: 'sampleReceived',
    icon: {name: IconName.Truck, ...iconProps},
  },
  sampleNotReceived: {
    label: 'Sample not received',
    value: 'sampleNotReceived',
    icon: {name: IconName.Truck, ...iconProps},
  },
  uploadReport: {label: 'Upload report', value: 'uploadReport', icon: {name: IconName.FilledCheckbox, ...iconProps}},
  downloadForm: {label: 'Submission form', value: 'downloadForm', icon: {name: IconName.Description, ...iconProps}},
  dismissReview: {label: 'Dismiss review', value: 'dismissReview', icon: {name: IconName.Description, ...iconProps}},
};

const Button = styled(PrimaryButton)`
  width: 200px;
  margin-top: 24px;
  margin-left: 20px;
  padding: 0 4px;
`;

const ShowRequiredTestsSection = styled.div`
  margin-top: 16px;
`;

const Hint = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size12};
  line-height: 16px;
  color: ${ColorPalette.gray44};
  margin-bottom: 8px;
`;

const ShowRequiredTestsButton = styled(PrimaryButton)`
  width: 80%;
`;

interface IExternalProps {
  horseDetails: IOrderHorseDetailsAdmin;
  ownerName: string;
  orderId: number;
  isClearStatuses?: boolean;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IExternalProps;

function OrderHorse(props: AllProps) {
  const {
    horseDetails,
    ownerName,
    orderId,
    orderHorseReleaseRequesting,
    releaseOrderHorse,
    setSampleStatus,
    downloadForm,
    setSampleStatusRequesting,
    changeReviewStatusRequesting,
    changeReviewStatus,
    getRequiredTests,
    requiredTests,
    requiredTestsLoading,
    isClearStatuses,
  } = props;
  const {id, packagesDetails, sample, name, reports, owner} = horseDetails;

  const [statuses, setStatuses] = useState<IOrderStatused>();
  const [isListenerLoaded, setIsListenerLoaded] = useState(false);

  const {onUpdateOrder} = useOnUpdateOrder();

  const {listen} = useEventBus();

  const onSetStatuses = useCallback(
    (data: IOrderHorseStatuses) => {
      if (data.orderId === orderId && data.horseId === id && !statuses) {
        setStatuses(data.statuses);
      }
    },
    [id, orderId, statuses]
  );

  useEffect(() => {
    const eventOrderStatusChanged = listen(EventName.OrderStatusChanged, onSetStatuses);
    setIsListenerLoaded(true);

    return () => {
      eventOrderStatusChanged.stopListening();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {uploadReportModal, openUploadReportModal} = useUploadReportModal({
    onSuccess: onUpdateOrder,
    horseId: id,
    orderId,
    horseName: name,
    ownerName: ownerName,
  });
  const {updatePaymentModal, openUpdatePaymentModal} = useUpdatePaymentModal({onSuccess: onUpdateOrder, orderId});

  const {requestNewSampleModal, openRequestNewSampleModal} = useRequestNewSampleAction({
    onSuccess: onUpdateOrder,
  });
  const openRequestNewSampleModalHandler = useCallback(
    () => openRequestNewSampleModal(orderId, id),
    [openRequestNewSampleModal, orderId, id]
  );

  const [isChooseAnotherSampleModal, setIsChooseAnotherSampleModal] = useState(false);
  const openChooseAnotherSampleModal = useCallback(() => setIsChooseAnotherSampleModal(true), []);
  const closeChooseAnotherSampleModal = useCallback(() => setIsChooseAnotherSampleModal(false), []);

  const [isRequiredTestsModalOpen, setIsRequiredTestsModalOpen] = useState(false);
  const openRequiredTestsModal = useCallback(() => {
    getRequiredTests(orderId, id);
    setIsRequiredTestsModalOpen(true);
  }, [getRequiredTests, id, orderId]);
  const closeRequiredTestsModal = useCallback(() => setIsRequiredTestsModalOpen(false), []);

  const [isEditSampleModalOpen, setIsEditSampleModalOpen] = useState(false);
  const openEditSampleModal = useCallback(() => setIsEditSampleModalOpen(true), []);
  const closeEditSampleModal = useCallback(() => setIsEditSampleModalOpen(false), []);

  const {openSampleNotReceivedModal, openSampleReceivedModal, setSampleStatusModal} = useSetSampleStatusAction({
    action: setSampleStatus,
    onSuccess: () => {},
  });

  const downloadSubmissionForm = useCallback(() => {
    downloadForm(orderId, id);
  }, [downloadForm, id, orderId]);

  useOnSuccessCommunication(setSampleStatusRequesting, onUpdateOrder);
  useOnSuccessCommunication(changeReviewStatusRequesting, onUpdateOrder);

  const {openReleaseOrderHorseModal, releaseOrderHorseModal} = useReleaseOrderHorseAction({
    onSuccess: onUpdateOrder,
    communication: orderHorseReleaseRequesting,
    action: releaseOrderHorse,
    animalId: id,
    orderId,
  });

  const {confirmModal: dismissReviewConfirmModal, openConfirmModal: openDismissReviewConfirmModal} = useConfirmModal({
    confirmDescription: 'Are you sure you want to dismiss review?',
    confirmCommunication: changeReviewStatusRequesting,
    successMessage: 'Review successfully dismissed',
    onConfirmAction: changeReviewStatus,
    confirmId: 'dismissReviewConfirm',
    onSuccess: onUpdateOrder,
  });

  const navigate = useNavigate();

  const redirectToReviewOnlineReport = useCallback(() => {
    navigate(`/review-admin/online-report/${id}/${orderId}/summary`);
  }, [id, navigate, orderId]);

  const sampleDetails = [
    {
      title: 'Sample ID',
      value: <SampleId sampleId={sample?.sampleId} hasSample={statuses?.hasSample} onEditClick={openEditSampleModal} />,
    },
    {
      title: 'Sample Date',
      value:
        (statuses?.hasSample && sample?.receivedDate && withDate(sample.receivedDate, FRONTEND_DATE3)) ||
        'Not received',
    },
  ];

  const menuItemsFunc = {
    updatePayment: openUpdatePaymentModal,
    sampleReceived: () => openSampleReceivedModal(orderId, id),
    sampleNotReceived: () => openSampleNotReceivedModal(orderId, id),
    uploadReport: openUploadReportModal,
    downloadForm: downloadSubmissionForm,
    dismissReview: () => openDismissReviewConfirmModal({horseId: id, orderId, reviewStatus: 'Waiting'}),
  };

  const statusItemsFunc = {
    orderPlaced: menuItemsFunc.updatePayment,
    paymentReceived: menuItemsFunc.sampleReceived,
    sampleReceived: redirectToReviewOnlineReport,
    resultsReady: redirectToReviewOnlineReport,
    reviewReady: openReleaseOrderHorseModal,
  };

  const availableSampleFunc = statuses?.hasSample ? menuItems.sampleNotReceived : menuItems.sampleReceived;

  const statusMenu = {
    items: [menuItems.updatePayment, availableSampleFunc]
      .concat(statuses?.hasPayment ? [menuItems.uploadReport] : [])
      .concat(statuses?.status === OrderStatus.reviewReady ? [menuItems.dismissReview] : []),
    onSelect: (item: string) => menuItemsFunc[item](),
  };

  const paymentMenu = {
    items: [menuItems.updatePayment],
    onSelect: (item: string) => menuItemsFunc[item](),
  };

  const sampleMenu = {
    items: [menuItems.downloadForm].concat([menuItems.updatePayment, availableSampleFunc]),
    onSelect: (item: string) => menuItemsFunc[item](),
  };

  const isShowRequiredTestsButton = statuses?.hasNotResults;

  return (
    <>
      {updatePaymentModal}
      {uploadReportModal}
      {requestNewSampleModal}
      {releaseOrderHorseModal}
      {dismissReviewConfirmModal}
      {setSampleStatusModal}

      {sample && (
        <>
          <ModalWindow isOpen={isEditSampleModalOpen} onClose={closeEditSampleModal}>
            <ChangeSampleForm
              adminVisitorType={SelectedStrategy.Admin}
              sample={sample}
              onSuccess={closeEditSampleModal}
            />
          </ModalWindow>

          <ModalWindow isOpen={isChooseAnotherSampleModal} onClose={closeChooseAnotherSampleModal}>
            <ChooseAnotherSampleForm
              orderId={orderId}
              horseId={id}
              sample={sample}
              onSuccess={closeChooseAnotherSampleModal}
            />
          </ModalWindow>
        </>
      )}

      <ModalWindow isOpen={isRequiredTestsModalOpen} onClose={closeRequiredTestsModal}>
        <RequiredTestsList
          horseName={name}
          ownerName={ownerName}
          requiredTests={requiredTests}
          isLoading={requiredTestsLoading.isRequesting}
        />
      </ModalWindow>

      {isListenerLoaded && (
        <RecordDetails>
          <Column label="Status" width="20%" menu={statusMenu}>
            <StatusDetails
              orderId={orderId}
              horseId={id}
              statusItemsFunc={statusItemsFunc}
              isClearStatuses={isClearStatuses}
              openRequiredTestsModal={openRequiredTestsModal}
            />
          </Column>
          <Column label="Packages details" width="25%" menu={paymentMenu}>
            <PackagesPrice
              total={packagesDetails.totalPrice}
              packagesPrices={packagesDetails.packages.map((x) => ({...x, type: PackageType.Package}))}
              isAssociation={false}
            />
            {isShowRequiredTestsButton && (
              <ShowRequiredTestsSection className="d-flex flex-column align-items-center">
                <Hint>Incomplete Tests</Hint>
                <ShowRequiredTestsButton size="small" variant="outlined" onClick={openRequiredTestsModal}>
                  Show required tests list
                </ShowRequiredTestsButton>
              </ShowRequiredTestsSection>
            )}
            {packagesDetails.packages[0].activationCode?.code && (
              <div className="p-4">
                <div className="d-flex align-items-center">
                  <h4 className="m-0 mr-2">{packagesDetails.packages[0].activationCode?.code}</h4>
                  <div
                    className={`badge rounded-pill ${acStatusToBadgeColor(
                      packagesDetails.packages[0].activationCode?.status
                    )}`}
                    style={{height: 'fit-content'}}
                  >
                    {packagesDetails.packages[0].activationCode?.status}
                  </div>
                </div>
                {owner && (
                  <div className="mt-2">
                    <span>Redeemed by:</span>
                    <br />
                    <div>
                      <AvatarCell
                        type="owner"
                        avatarUrl={owner.avatar}
                        profileUrl={`/admin/user/user-profile/${owner.id}/horses`}
                        label={`${owner?.firstName} ${owner?.lastName}`}
                        onLabelClick={() => {}}
                        isMarkedIcon={false}
                        hasOnlineReportAccess={false}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </Column>
          <Column label="Sample" width="20%" menu={sampleMenu}>
            <Details records={sampleDetails} />
            <Button onClick={openRequestNewSampleModalHandler}>Resubmit sample</Button>
            {statuses?.hasSample && <Button onClick={openChooseAnotherSampleModal}>Choose another sample</Button>}
          </Column>
          <Column label="Reports" width="20%">
            <HorseReportList
              horseReports={reports}
              uploadReportHandler={openUploadReportModal}
              enableUploadReport={!!statuses?.hasPayment}
              onSuccess={onUpdateOrder}
              horseId={id}
            />
          </Column>
        </RecordDetails>
      )}
    </>
  );
}

const mapStateToProps = (state: IAppState) => ({
  setSampleStatusRequesting: orderSelectors.selectCommunication(state, 'setSampleStatusRequesting'),
  orderHorseReleaseRequesting: adminOrdersSelectors.selectCommunication(state, 'orderHorseReleaseRequesting'),
  changeReviewStatusRequesting: adminOnlineReportSelectors.selectCommunication(state, 'changeReviewStatusRequesting'),
  requiredTests: onlineReportSelectors.selectRequiredTests(state),
  requiredTestsLoading: onlineReportSelectors.selectCommunication(state, 'requiredTestsLoading'),
});

const mapDispatchToProps = {
  setSampleStatus: orderActions.setSampleStatus,
  downloadForm: orderActions.downloadHorseSubmissionForm,
  releaseOrderHorse: adminOrdersActions.releaseOrderHorse,
  changeReviewStatus: adminOnlineReportActions.changeReviewStatus,
  getRequiredTests: onlineReportActions.getRequiredTests,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(OrderHorse));

export default withDynamicModules(Connected, [AdminOrdersModule, TestReportModule]);
